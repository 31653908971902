<template>
  <main id='docs' class='page-content' role='main'>
    <div class="subheader hidden-lg-up d-flex">
      <portal to="ariane">
        <h1 class="subheader-title mb-2 text-info">
          <i class="fal fa-book m-2 text-info"></i>
          {{ $t('docs.title.page') }}
        </h1>
      </portal>
    </div>
    <div class='col-xl-8 col-lg-8 col-md-8 m-auto'>
      <div class='card o-hidden border-0 shadow-lg my-5'>
        <div class='card-body p-0'>
          <b-tabs position='is-left'
                  size='is-medium'
                  :type='type'
                  vertical
                  :expanded='expanded'>

            <b-tab-item :label="`${$t('docs.title.versions') }`" :disabled='!commits || commits.length === 0'>
              <ul v-if='commits && commits.length > 0' v-for='(commit, index) in commits'
                  :key='index'>
                <li><p> <span class="text-lg leading-6 font-medium text-blue-500">{{ $t(`docs.repo.${commit.repo}`) }} </span> : {{ $t('docs.versions.last_updated_on') }}
                  {{ moment(commit.author.date).format('D MMMM YYYY') }}, {{ $t('docs.versions.version') }} :
                  {{ commitVersion(commit.url) }} </p></li>
              </ul>
            </b-tab-item>

            <b-tab-item id='changelogs' :label="`${$t('docs.title.changelogs') }`" :disabled='!changelogs || changelogs.length === 0'>
              <div v-if='changelogs && changelogs.length > 0 ' v-for='(changelog, index) in changelogs'
                   :key='index' class='my-4 mx-4'>
                <p class="text-lg leading-6 font-medium text-blue-500">{{ $t(`docs.repo.${changelog.repo}`) }} </p>
                <div v-html='changelog.content'> {{ changelog.content }}</div>
              </div>
            </b-tab-item>

            <b-tab-item :label="` ${$t('docs.title.user_manual') }`" disabled>

            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </div>
  </main>

</template>

<script>
export default {
  name: 'Documentations',
  data() {
    return {
      expanded: false,
      atRight: false,
      size: null,
      type: null,
      repos :['reborn-client', 'reborn', 'crm'],
      commits: [],
      changelogs: []
    }
  },
  created() {
    this.repos.forEach(el=>this.getGitHubData(el))
    this.repos.forEach(el=>this.getGitHubChangeLog(el))
  },
  methods: {
    getGitHubData(repo) {
      fetch(`https://api.github.com/repos/loran750/BougeMaVille-${repo}/commits`,
        {
          headers: {
            Authorization:
              'Bearer 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',
            Accept: 'application/vnd.github+json',
            'X-GitHub-Api-Version': '2022-11-28'
          }
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data && data[0] && data[0].commit) {
            let response = data[0].commit
            response.repo = repo
            this.commits.push(response)
          }
        })
        .catch((error) => {
          console.error(
            error
          )
        })
    },
    commitVersion(url) {
      return url.substring(url.lastIndexOf('/') + 1).substring(7, 0)
    },
    getGitHubChangeLog(repo) {
      fetch(`https://api.github.com/repos/loran750/BougeMaVille-${repo}/contents/CHANGELOG.md`,
        {
          headers: {
            Authorization:
              'Bearer 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',
            Accept: 'application/vnd.github+json',
            'X-GitHub-Api-Version': '2022-11-28'
          }
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data && data.content) {
            var response = Buffer.from(data.content, 'base64')
            var responsetoString = response.toString()
            this.changelogs.push({ repo: repo, content: responsetoString })
          }
        })
        .catch((error) => {
          console.error(
            error
          )
        })
    }
  }

}

</script>

<style>
#docs ul li {
  list-style: initial !important;
}

#docs .tab-content {
  padding: 2% 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#changelogs.tab-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
#docs .b-tabs.is-vertical > .tabs {
  background:#ECF1FA;
}
#docs  .tabs li a {
  border :none !important;
}

#docs  .tabs li.is-active a {
  border-bottom-color: #004085 !important;
  color: #004085;
  font-weight: 500;
}


</style>
